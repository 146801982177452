.nav_categories {
  display: flex;
  gap: 38px;
  flex-wrap: nowrap;
  margin-bottom: 18px;
  @media screen and (max-width: 600px) {
    gap: 18px;
  }
  @media screen and (max-width: 375px) {
    flex-wrap: wrap;
  }
  .categ {
    height: 80px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
    padding: 12px 24px;
    @media screen and (max-width: 600px) {
      padding: 4px 4px 8px 4px;
      border: none;
      width: 64px;
    }
    .p_c_img {
      width: 56px;
      height: 56px;
    }
  }
  .active_cat {
    background: #ff9f1c;
    color: white;
  }
}

@media screen and (max-width: 800px) {
  .nav_categories {
    width: 100%;
    justify-content: space-between;
    gap: 8px;

    .categ {
      // flex: auto;
      height: 100%;
      border-radius: 8px;
      // width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;

      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      color: #000000;
    }
    .active {
      background: #ff9f1c;
      color: white;
    }
  }
}
