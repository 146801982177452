.carousel {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1051px) {
    // .keen-slider__slide {
    // max-width: unset !important;
    // min-width: 400px !important;
    // }
  }

  [class^="number-slide"],
  [class*=" number-slide"] > img {
    height: 100%;
    width: 100%;
    min-height: 440px;
    max-height: 477px;
    border-radius: 15px;
  }
  @media only screen and (max-width: 700px) {
    [class^="number-slide"],
    [class*=" number-slide"] > img {
      height: 100%;
      width: 100%;
      min-height: 100%;
    }
  }
  .navigation-wrapper {
    position: relative;
    width: 100%;
    // min-height: 100%;
  }

  .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
  }

  .dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    background: #000;
  }

  .arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;
  }

  .arrow--left {
    left: 5px;
  }

  .arrow--right {
    left: auto;
    right: 5px;
  }

  .arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
  }
}
