@use "../ProductCard/ProductCard";
.list_items {
  display: flex;
  gap: 38px;
  flex-wrap: wrap;
  max-height: 740px;
  overflow: auto;
  @media screen and (max-width: 900px) {
    gap: 18px;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.list_items::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.list_items {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.spinner_product {
  min-height: 80px;
  width: 100%;
}
