.product_card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 11.9298px;
  padding: 12px;
  // width: 100%;
  flex: 1;
  min-width: 232px;
  // max-width: 500px;
  width: 100%;
  cursor: pointer;
  .media {
    width: 100%;
    height: 167.02px;
    border-radius: 12px;
    position: relative;
    background-size: cover;
    background-position: center;
    img {
      width: 100%;
      height: 167.02px;
      border-radius: 12px;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }
    .av_product {
      position: absolute;
      bottom: 8px;
      left: 8px;
      background: #39c5a3;
      border-radius: 178.947px;
      font-weight: 400;
      font-size: 14.9123px;
      line-height: 28px;
      text-align: center;
      color: #ffffff;
      padding: 1px 7px;
    }
    .not_available {
      background: #fd2a2a;
    }
  }
  .title_card {
    font-weight: 700;
    font-size: 17.8947px;
    line-height: 34px;
    color: #000000;
    margin: 4px 0;
  }
  .size_product {
    font-weight: 700;
    font-size: 17.8947px;
    line-height: 34px;
    color: #000000;
    span {
      font-weight: 400;
      font-size: 11.9298px;
      line-height: 22px;
      color: #868889;
    }
  }
  .place_product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .place_product {
      font-weight: 400;
      font-size: 11.9298px;
      line-height: 22px;
      color: #000000;
    }
    .price_product {
      font-style: normal;
      font-weight: 700;
      font-size: 17.8947px;
      line-height: 34px;
      background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      span {
        font-weight: 400;
        font-size: 11.9298px;
        line-height: 22px;
        color: #868889 !important;
      }
    }
  }
  .action_card {
    width: 100%;
    margin-top: 6px;
    .buy_btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 39.96px;
      background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
      border-radius: 5.96491px;
      border: none;
      padding: 6px;
      font-weight: 700;
      font-size: 14.9123px;
      line-height: 28px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      span {
        font-weight: 400;
        font-size: 11.9298px;
        line-height: 22px;
      }
    }
  }
}
