.form_input {
  // text-align: left;
  // margin-bottom: 18px;
  margin-bottom: -28px;

  input {
    margin-top: 3px;
  }
  p {
    color: red;
    font-size: 11px;
    margin-top: 0px;
    height: 30px !important;
  }
}
.err_phone {
  border: 1px solid red !important;
}

.phone-input-container {
  display: flex !important;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  height: 44px;
  overflow: hidden;
  border: 1px solid #868889;
  border-radius: 18px;

  .inputphone {
    border: none !important;
    height: 100%;
    width: 100%;
    margin: 0;
    outline: none;
    padding: 0 6px 0 0;
  }

  .ant-select-selector {
    border: none !important;
    overflow: hidden !important;
    border-radius: 0 !important;
    height: 70px;
  }
  .ant-select.ant-select-single.ant-select-show-arrow {
    // margin-top: 3px;
  }
  .ant-select-focused {
    border: none !important;
    overflow: hidden !important;
    border-radius: 0 !important;
  }
}

.select-flag-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;

  .country-flag {
    width: 18px;
    height: 18px;
    border-radius: 100%;
  }
  span::before {
    color: red;
  }
}
