.product_type_filter {
  .nav_filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 34px;
      text-align: right;
      color: #000000;
      padding: 0;
      margin: 0;
    }
    .collapse_items {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      user-select: none;

      .btn_select_all {
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        text-align: right;
        color: #000000;
        cursor: pointer;
        user-select: none;
      }
      .clicked_all {
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        text-align: right;
        color: #ff9f1c;
        cursor: pointer;
        user-select: none;
      }
      .point {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #000000;
      }
    }
  }
  .content_collapse {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-top: 13px;
    flex-wrap: wrap;
    user-select: none;
    max-height: 200px;
    overflow: auto;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .content_collapse::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .content_collapse {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .btn_collapse {
    height: 30px;
    border-radius: 24px;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: right;
    color: #ffffff;
    padding: 4px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #000000;
    background: #ffffff;
    /* gray */

    border: 1px solid #868889;
    border-radius: 24px;
  }
  .clicked {
    background: #ff9f1c;
    border: 1px solid #f2f2f2;
    color: white;
  }
}
